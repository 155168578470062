.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #2c3e50;
  padding: 20px;
  color: white;
}

main {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logic-gate {
  background-color: white;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.logic-gate h2 {
  color: #2c3e50;
}
.gate-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gate-visual-container {
  display: flex;
  align-items: center;
}

.inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;
  margin-right: 10px;
}

.input-switch {
  display: flex;
  align-items: center;
}

.input-switch label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.gate-visual {
  margin: 20px 0;
}

.output-display {
  font-size: 1.2em;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.output-value {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
}

.output-value.on {
  background-color: #2ecc71;
  color: white;
}

.output-value.off {
  background-color: #e74c3c;
  color: white;
}

.gate-selector {
  margin-bottom: 5px;
}

.gate-selector select {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.gate-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gate-with-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.input-switches {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
}

.input-switch {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.input-switch label {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.input-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.input-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.input-switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.input-switch input:checked + .slider {
  background-color: #2196f3;
}

.input-switch input:checked + .slider:before {
  transform: translateX(20px);
}

.input-label {
  margin-left: 10px;
  font-size: 0.8em;
}

.gate-with-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.input-switches {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -120px; /* Increased to accommodate labels */
  top: 50%;
  transform: translateY(-50%);
}

.input-switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
  width: 110px; /* Set a fixed width for alignment */
}

.input-label {
  margin-right: 10px;
  font-size: 0.9em;
  text-align: right;
  flex-grow: 1;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  flex-shrink: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: #2196f3;
}

.switch input:checked + .slider:before {
  transform: translateX(20px);
}

.gate-interactive {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0px;
}

.logic-gate {
  background-color: white;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1000px;
}

.gate-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.gate-interactive {
  flex: 1;
  max-width: 500px;
  margin-right: 20px;
}

.gate-visual {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.gate-visual svg {
  width: 100%;
  height: auto;
}

.truth-table {
  flex: 1;
  max-width: 300px;
}

.truth-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.truth-table th,
.truth-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.truth-table th {
  background-color: #f2f2f2;
}

@media (max-width: 768px) {
  .logic-gate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    box-shadow: none;
  }

  .gate-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .gate-interactive {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .truth-table {
    max-width: 100%;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-switch {
    margin-bottom: 0px;
  }

  .output-display {
    margin-top: 10px;
  }

  .App-header {
    background-color: #2c3e50;
    padding: 5px;
    color: white;
  }
}
